import React from "react";
import { Link } from "react-router-dom";
import NewsletterSignup from "../NewsletterSignup";

import "./styles.scss";

import triangle from "../../img/triangle.svg";
import hero from "../../img/hero.png";

export default function Body() {
  return (
    <div className="Body">
      <div className="intro-container">
        <div className="box">
          <img src={triangle} className="triangle" alt="triangle" />
          <h1>
            Alpine Mode is the first app made specifically for mountaineering
            and alpine climbing with precision metrics that cater to performance
            in the mountains.
          </h1>
          <p>
            Made for alpine terrain by mountaineer, alpinist, and rock climber
            Wesley Grubbs, this app seeks to fill the gaps in performance and
            feedback metrics while you make your approach to base camp, climb
            the coveted knife ridge, start your descent, or if you’re training
            in the hills. The metrics provided in Alpine Mode are specific to
            mountaineering, mountain biking, backcountry skiing, or more
            advanced hiking.
          </p>
          <p className="redText">
            Available to download in early 2025 <br />
            <Link to="/privacy-policy">Privacy Policy</Link> <br />
            <Link to="/contact">Contact Us</Link>
          </p>
        </div>
        <div className="box">
          <img src={hero} className="hero" alt="Apple Ultra Watch Sports App" />
        </div>

        <div className="coreFeaturesWrapper">
          <div className="doubleRow">
            <h1>Core Features</h1>
          </div>

          <div className="box">
            <h2>Vertical Speed Measurement</h2>
            <p>
              Elevation change is measured as a unique measurement of pace for
              both ascent and descent.
            </p>
          </div>

          <div className="box">
            <h2>Target Elevation</h2>
            <p>
              Whether you're going to basecamp or the summit, set your target
              elevation and quickly view your progress.
            </p>
          </div>

          <div className="box">
            <h2>Blood Oxygen and BPM</h2>
            <p>
              Keep a tab on your blood oxygen as you climb to your destination
              and monitor your heart rate to optimize your performance.
            </p>
          </div>

          <div className="box">
            <h2>Apple Workout Integration</h2>
            <p>
              Quickly start a workout as an Apple Workout. Data is stored along
              with Apple account including rings and other related stats.
            </p>
          </div>
        </div>
      </div>

      <NewsletterSignup />
    </div>
  );
}
