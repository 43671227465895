import React from "react";

import "./styles.scss";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="Footer">
      <ul className="links">
        <li>
          Made by{" "}
          <a
            href="https://www.pitchinteractive.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pitch Interactive
          </a>
        </li>
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </div>
  );
}
