import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";

import Header from "./components/Header";
import Body from "./components/Body";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

import "./App.scss";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">
        <div className="wrapper">
          <Header />

          <Routes>
            <Route path="/" element={<Body />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>

          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
