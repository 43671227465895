import React from "react";
import { Link } from 'react-router-dom';

import logo from "../../img/logo-small.svg";

import "./styles.scss";

export default function Header() {
  return (
    <header className="Header">
      <Link to="/"><img src={logo} className="logo-small" alt="Alpine Mode" /></Link>
      
      <nav className="nav-links">
        <ul className="links">
          <li>
            <a href="#features">Features</a>
          </li>
          <li>
            <a href="#purpose">Purpose</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href="#download">Download Img</a>
          </li>
        </ul>
      </nav>
    </header>
  );
}
