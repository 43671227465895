import React from "react";

import "./styles.scss";
import wespic from "../../img/wes_grubbs_hoffmanridge.jpg";

import { Email } from "react-obfuscate-email";
//import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <div className="Contact">
      <h1>Contact Us</h1>
      <p>
        Interested in becoming a tester? Do you have an Apple watch and find
        yourself in the mountains frequently?
      </p>
      <p>
        We are looking for volunteers to help test Alpine Mode in various
        conditions and circumstances. In particular if you enjoy:
      </p>
      <ul>
        <li>Mountaineering</li>
        <li>Alpine Climbing</li>
        <li>Mountain Biking</li>
        <li>Backcountry Skiing</li>
        <li>Hiking</li>
        <li>Backpacking</li>
      </ul>
      <p>
        While we are initially building Alpine Mode for mountaineering and
        alpine climbing, the metrics we are developing are closely related to
        other mountain sports.
      </p>
      <p>
        You can email us directly at{" "}
        <Email email="info@alpine-mode.com">info [at] alpine-mode.com</Email>.
      </p>

      <div className="image-wrapper">
        <img
          src={wespic}
          className="wespic"
          alt="Wesley Grubbs having fun on Hoffman Ridge"
        />
      </div>

      <p>
        Alpine Mode is a passion project by Wesley Grubbs, an avid mountain
        adventurer based in Oakland, California. Wesley is a mountaineer,
        alpinist, and rock climber. He is also a decorated expert data
        visualization and UI designer, engineer, and founder of{" "}
        <a
          href="https://www.pitchinteractive.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pitch Interactive
        </a>
        , a data visualization studio for over 18 years. Blending these two
        passions is where Alpine Mode comes to play. Wesley has been building
        Alpine Mode to fill gaps to measure performance and progress in the
        mountains. Gaia is a great tool for maps. Strava is great for runners or
        cyclists. Apple workouts are great for the gym, but limited in the
        mountains, despite the advertising around the Ultra Watch showing people
        scrambling and climbing. Alpine Mode is the tool you want while you are
        in the mountains. On your approach to base camp or your doing a
        car-to-car push in a day. It's not mean to replace some robust and key
        tools like Gaia, it is mean to work togethe with Apple Workouts (and
        potentially Strava) in giving you instant feedback while you are on the
        approach, on the climb, or on the descent.
      </p>

      <h2>Ambitions for the Future</h2>
      <p>
        We are just at the beginning and have a long ways to go. We hope to grow
        and ultimately build a team. If you are interested in joining us, please
        reach out. We will be looking for:
      </p>
      <ul>
        <li>iOS Development</li>
        <li>UI/UX Design</li>
        <li>Backend + Full Stack Development</li>
        <li>Project Management</li>
        <li>Marketing, PR, and outreach</li>
        <li>Garmin and Android Development</li>
      </ul>

      <p>
        This project is currently entirely self-funded. As we grow, if you have
        a passion for the mountains and interest in joining our team, reach out
        and say 'Hi'. Let us know what you are interested in and how you think
        your skills can help. We are no asking for any free work (other than app
        testing), but would like to start building a network of passioned
        professionals.
        <br />
        <br />
      </p>
    </div>
  );
}
